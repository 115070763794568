import { GET_STRIPE_PAYMENTS } from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";



export const PaymentData = (data) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("/payment/create", data)
        .then((res) => {
            console.log(res.data.data)
            dispatch({
                type: GET_STRIPE_PAYMENTS,
                payload: res.data.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};