import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Divider,
  CardContent,
  TextField,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { GetUserActivity } from "store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoaderSpinner from "../../../utils/Loader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function Tracking() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activity } = useSelector((state) => state?.Users);
  const PropsData = useState(location.state);
  const props = PropsData[0]?.data;
  const { loader_status } = useSelector((state) => state.loader);
  const [image, setImage] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    if (props) {
      getActivity();
    }
  }, [props, startDate, endDate]);

  function getActivity() {
    let query = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      employee_id: props,
    };
    dispatch(GetUserActivity(query));
  }

  console.log("Activity", activity);

  return (
    <div>
      {image && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setImage(null)}
          enableZoom={true} // Allow zooming
        />
      )}
      {loader_status ? (
        <LoaderSpinner />
      ) : (
        <MainCard className=" 2xl:min-h-[90%] xl:min-h-[90%] lg:min-h-[90%] md:min-h-[90%] sm:min-h-[90%] xsm:min-h-[90%] xxs:min-h-[90%] xxxs:min-h-[90%]  overflow-y-scroll mb-2">
          <CardContent className="xl:!p-[16px] lg:!p-[16px] md:!p-[16px] sm:!p-[16px] xsm:!p-[7px] xxs:!p-[7px] xxxs:!p-[7px]">
            <Box className="flex items-center justify-between mb-4">
              <h1 className="font-bold text-lg">{t("emp_activity")}</h1>
              <Box className="flex mt-5 items-center">
                <DatePicker
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(date) => setStartDate(date)}
                  label="Start Date"
                  value={dayjs(startDate)}
                />
                <span className="font-bold text-lg mx-4"> - </span>
                <DatePicker
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(date) => setEndDate(date)}
                  label="End Date"
                  value={dayjs(endDate)}
                />
              </Box>
            </Box>
            <Divider className="my-5" />
            <Box className="mt-5 items-center">
              {/* Assuming you have an array of activities to map over */}
              {activity && activity?.length > 0 ? (
                activity.map((item, index) => (
                  <>
                    <span className="font-bold text-lg">
                      {moment(item?.date).format("DD-MM-YYYY")}
                    </span>{" "}
                    <Grid className="mt-1" container spacing={2}>
                      {/* 6 columns layout (12/6 = 2) */}
                      {item?.screenshots?.map((data, index) => (
                        <Grid item xs={2} key={index}>
                          <Box>
                            <span className="font-bold text-gray-text text-sm">
                              {data.time}
                            </span>
                            <button
                              onClick={() => setImage(data.imageUrl)}
                              aria-label={`View screenshot taken at ${data.time}`}
                              className="border-none bg-transparent p-0"
                            >
                              <img alt={data.time} src={data.imageUrl} />
                            </button>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    <Divider className="my-5" />
                  </>
                ))
              ) : (
                <Typography
                  variant="h5"
                  className="text-base text-center font-semibold mb-5"
                >
                  {t("no_acitivty")}
                </Typography>
              )}
            </Box>
          </CardContent>
        </MainCard>
      )}
    </div>
  );
}
