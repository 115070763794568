import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { MdDelete, MdAddCircleOutline } from "react-icons/md";
import Coin from "../../assets/images/icons/coin.png";
import { GetAllRoadMap, DeleteRoadMap } from "store/Road_Map/roadmap.action";
import LoaderSpinner from "../../utils/Loader";
//Redux
import { useSelector, useDispatch } from "react-redux";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// ==============================|| Clients ||============================== //

function RoadMap() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const { RoadMap } = useSelector((state) => state?.RoadMap);
  const { login_Session } = useSelector((state) => state.auth);
  const { loader_status } = useSelector((state) => state.loader);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(loader_status);
  }, [loader_status]);

  const columns = [
    {
      field: "id",
      width: 50,
      hide: true,
      headerName: t("member_table_h_sr"),
      sortable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ordernumber",
      headerName: t("order_no"),
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "image",
      headerName: t("trophy_image"),
      width: 150,
      disableExport: true,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "row-reverse",
                justifyContent: "center",
              }}
            >
              <img
                alt="img"
                src={param.row.image}
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "25px",
                  display: "block",
                  objectFit: "cover",
                }}
              />
            </Box>
          </>
        );
      },
    },
    {
      field: "image_hover",
      headerName: t("hover_trophy_image"),
      width: 150,
      disableExport: true,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "row-reverse",
                justifyContent: "center",
              }}
            >
              <img
                alt="img"
                src={param.row.image_hover}
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "25px",
                  display: "block",
                  objectFit: "cover",
                }}
              />
            </Box>
          </>
        );
      },
    },

    {
      field: "title",
      headerName: t("trophy_title"),
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "coins",
      headerName: t("table_h_coins"),
      width: 150,
      headerClassName: "super-app-theme--header",
      selector: (row) => row.coins,
      renderCell: (param) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".3rem",
            }}
          >
            <img
              alt="img"
              src={Coin}
              style={{
                width: "40px",
                objectFit: "cover",
              }}
            />
            <Typography
              variant="subtitle1"
              gutterBottom
              className="capitalize text-sm text-gray-text font-[500]"
            >
              {param.row.coins}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "createdAt",
      headerName: t("created_at"),
      width: 150,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "action",
      headerName: t("member_table_h_action"),
      width: 250,
      headerClassName: "super-app-theme--header",
      selector: (row) => row.action,
      renderCell: (param) => {
        return (
          <div className="flex justify-center gap-5 items-center">
            <Button
              onClick={() => {
                navigate("/road_map/add", {
                  state: { data: RoadMap[param.row?.id - 1] },
                });
              }}
              sx={{
                background: "#CCCCCC",
                padding: "1px 8px!important",
                fontSize: "0.8rem!important",
                borderRadius: "15px!important",
              }}
              className="flex items-center gap-1 leading-loose text-black hover:bg-[#CCCCCC] shadow-lg bg-[#e9e6e6]"
              size="small"
              variant="contained"
            >
              {t("action_edit")}
              <BiEdit className="text-base font-bold" />
            </Button>
            {login_Session?.isSuperadmin && (
              <Button
                onClick={() => {
                  dispatch(
                    DeleteRoadMap(param?.row?.userdetail, login_Session?._id)
                  );
                }}
                sx={{
                  padding: "1px 8px!important",
                  fontSize: "0.8rem!important",
                  borderRadius: "15px!important",
                }}
                className="flex items-center gap-1 leading-loose  shadow-lg text-primary-text"
                size="small"
                variant="outlined"
              >
                {t("action_delete")}
                <MdDelete className="text-base font-bold" />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (RoadMap) {
      makeRow();
    } else {
      dispatch(GetAllRoadMap());
    }
  }, [RoadMap]);

  const makeRow = () => {
    const mapData = RoadMap;
    var data =
      Array.isArray(mapData) && mapData?.length > 0
        ? mapData?.map((data, id) => ({
            id: id + 1,
            image:
              data?.image == "null" || data?.image == null
                ? "No Image Found"
                : data?.image,
            image_hover:
              data?.hover_image == "null" || data?.hover_image == null
                ? "No Hover Image Found"
                : data?.hover_image,
            title: data?.title == null ? "-" : data?.title,
            coins: data?.coins == null ? "-" : data?.coins,
            ordernumber: data?.ordernumber == null ? "-" : data?.ordernumber,
            createdAt:
              data?.createdAt == null
                ? "-"
                : dayjs(data?.createdAt).format("DD-MMM-YYYY"),
            userdetail: data?._id,
          }))
        : mapData;
    setRow(data);
  };

  return (
    <>
      {checked ? (
        <LoaderSpinner />
      ) : (
        <>
          <Typography
            variant="h3"
            className="text-[white] font-normal flex items-center mb-4"
          >
            {t("road_map")}
          </Typography>
          <MainCard>
            {login_Session?.isSuperadmin && (
              <Box className="flex justify-end mb-4">
                <Button
                  component={Link}
                  to="/road_map/add"
                  sx={{
                    background:
                      "linear-gradient(to bottom, #30496b, #30b8d2)!important",
                  }}
                  className="flex items-center gap-1 text-white rounded-xl"
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  {t("add_road_map")}
                  <MdAddCircleOutline className="text-xl font-bold !text-white" />
                </Button>
              </Box>
            )}

            <Box
              sx={{
                height: 450,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#E8E8E8",
                  color: "#307493",
                },
              }}
            >
              <DataGrid
                sx={{ border: "unset!important" }}
                rows={row}
                localeText={{
                  noRowsLabel: "No Data to show",
                }}
                columns={columns}
                disableSelectionOnClick
                pageSize={10}
                // rowsPerPageOptions={[5]}
                components={{
                  Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </MainCard>
        </>
      )}
    </>
  );
}

export default RoadMap;
