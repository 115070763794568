import { GET_ALL_ORDERS } from "../actions";

const initialState = {
  allOrders: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    default:
      return state;
  }
}
