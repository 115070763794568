import { GET_ALL_ROADMAP } from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";

export const GetAllRoadMap = () => (dispatch) => {
  dispatch(changeflag(true));
  Axios.get("/roadmap/allroadmap")
    .then((res) => {
      dispatch({
        type: GET_ALL_ROADMAP,
        payload: res.data,
      });
      console.log("ba", res.data);

      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const RoadMapAdd = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/roadmap/addroadmap", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        dispatch(GetAllRoadMap());
        navigate("/utils/road_map");
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const UpdateRoadMap = (data, id, navigate) => (dispatch) => {
  console.log(data, "add data");
  dispatch(changeflag(true));
  Axios.put("/roadmap/" + id, data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        dispatch(GetAllRoadMap());
        if (navigate) {
          navigate("/utils/road_map");
        }
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const DeleteRoadMap = (id) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.delete("/roadmap/" + id)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        dispatch(GetAllRoadMap());
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const UploadUserImage =
  (formData, data, navigate, status, id) => (dispatch) => {
    dispatch(changeflag(true));

    Axios.post("/upload/addimage", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.error == false) {
          console.log(res.data);
          
          let path = res.data.path;
          let hoverImagePath = res.data.hoverImagePath;

          if (path) {
            data["image"] = path;
          }

          if (hoverImagePath) {
            data["hover_image"] = res.data.hoverImagePath;
          }

          if (status == "Add") {
            dispatch(RoadMapAdd(data, navigate));
          }
          if (status == "Update") {
            dispatch(UpdateRoadMap(data, id, navigate));
          }
        } else {
          toast.error(res.data.message);
        }
        dispatch(changeflag(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(changeflag(false));

        if (error.response) {
          toast.error(error?.response?.data?.message);
        } else if (error.request) {
          toast.error(error?.request);
        } else {
          toast.error(error?.message);
        }
      });
  };

export const DeleteImage = (data) => (dispatch) => {
  Axios.post("/upload/delete", data)
    .then((res) => {})
    .catch((error) => {});
};
