// assets
import { FaRegChartBar, FaUsers } from "react-icons/fa";
import { ImUsers, ImProfile } from "react-icons/im";
import {
  MdLogout,
  MdOutlineApps,
  MdOutlineVideoSettings,
  MdSync,
} from "react-icons/md";
import {
  RiDashboard3Line,
  RiUserAddFill,
  RiSettings4Line,
} from "react-icons/ri";
import { GoDesktopDownload } from "react-icons/go";
import { GiRank3 } from "react-icons/gi";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineTransaction } from "react-icons/ai";
import { RiShoppingCartLine } from "react-icons/ri";
import { RiLuggageCartLine } from "react-icons/ri";


// constant
const icons = {
  RiSettings4Line,
  GoDesktopDownload,
  RiDashboard3Line,
  FaRegChartBar,
  ImUsers,
  ImProfile,
  BsCalendarDate,
  MdLogout,
  FaUsers,
  MdOutlineApps,
  RiUserAddFill,
  GiRank3,
  AiOutlineTransaction,
  MdOutlineVideoSettings,
  MdSync,
  RiShoppingCartLine,
  RiLuggageCartLine
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboardClient = {
  id: "dashboardClient",
  title: "",
  type: "group",
  children: [
    {
      id: "client-metrics",
      title: "My Performance",
      type: "item",
      url: "/client",
      icon: icons.FaRegChartBar,
      breadcrumbs: false,
    },
    {
      id: "schedule",
      title: "Focus Schedule",
      type: "item",
      url: "/add-clients/add-schedule",
      icon: icons.BsCalendarDate,
      breadcrumbs: false,
    },
    {
      id: "blocked-apps",
      title: "Apps Control",
      type: "item",
      url: "/add-schedule/apps-environment",
      icon: icons.MdOutlineApps,
      breadcrumbs: false,
    },
    {
      id: "marketPlace",
      title: "MarketPlace",
      type: "item",
      url: "/utils/market-place",
      icon: icons.RiShoppingCartLine,
      breadcrumbs: false,
    },
    {
      id: "orders",
      title: "Orders",
      type: "item",
      url: "/utils/orders",
      icon: icons.RiLuggageCartLine,
      breadcrumbs: false,
    },
    {
      id: "users",
      title: "Add Members",
      type: "item",
      url: "/utils/users",
      icon: icons.FaUsers,
      breadcrumbs: false,
    },
    {
      id: "user-report",
      title: "Members Report",
      type: "item",
      url: "/utils/user-report",
      icon: icons.ImProfile,
      breadcrumbs: false,
    },
    {
      id: "rank-status",
      title: "Rank Status",
      type: "item",
      url: "/utils/rank-status",
      icon: icons.GiRank3,
      breadcrumbs: false,
    },
    {
      id: "download",
      title: "Downloads",
      type: "item",
      url: "/download/apps",
      icon: icons.GoDesktopDownload,
      breadcrumbs: false,
    },
    {
      id: "subscription",
      title: "Subscription History",
      type: "item",
      url: "/subscription/history",
      icon: icons.AiOutlineTransaction,
      breadcrumbs: false,
    },
    {
      id: "setting",
      title: "Settings",
      type: "item",
      url: "/utils/settings",
      icon: icons.RiSettings4Line,
      breadcrumbs: false,
    },
    {
      id: "invite",
      title: "Invite Friends",
      type: "item",
      url: "/invite/user",
      icon: icons.RiUserAddFill,
      breadcrumbs: false,
    },

    {
      id: "rankings",
      title: "Rankings",
      type: "item",
      url: "/utils/road_map",
      icon: icons.FaRegChartBar,
      breadcrumbs: false,
    },
    {
      id: "onboarding",
      title: "Retry the Onboarding",
      type: "item",
      url: "",
      icon: icons.MdSync,
      breadcrumbs: false,
    },
    {
      id: "tutorial",
      title: "Tutorial",
      type: "item",
      target: "_blank",
      external: "https://www.focusmonk.co/tutorial",
      url: "https://www.focusmonk.co/tutorial",
      icon: icons.MdOutlineVideoSettings,
      breadcrumbs: false,
    },
    {
      id: "logout",
      title: "Logout",
      type: "item",
      icon: icons.MdLogout,
      url: "",
      breadcrumbs: false,
    },
  ],
};

export default dashboardClient;
