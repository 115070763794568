import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeRegComponents/CheckoutForm";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PaymentData } from "../../../store/stripePayment/payment.action";
import config from "../../../config";
import LoaderSpinner from "../../../utils/Loader";
import { CompanyStatus } from "store/auth/auth.action";
import { useNavigate } from "react-router-dom";
import BlockedPage from "../BlockedPage/BlockedPage";
function StripeRegistration(cusData) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paymentintent } = useSelector((state) => state?.paymentintent);
  const { company_status } = useSelector((state) => state?.auth);

  const getStripeKey = () => {
    if (typeof window === "undefined") return "";
    if (window.location.hostname === "localhost")
      return config?.Public_key || "";
    if (window.location.hostname === "focusmonk.vercel.app")
      return config?.Public_key || "";
    return config?.Live_key || "";
  };
  const stripePromise = loadStripe(getStripeKey());
  const [clientSecret, setClientSecret] = useState();
  const companyId = window.location.href.split("/")[4];

  const [cusId, SetCusId] = useState();

  const { loader_status } = useSelector((state) => state.loader);
  const [isLaoding, setisLoading] = useState(false);
  const location = useLocation();
  const PropsData = useState(location.state);
  const props = PropsData[0];

  useEffect(() => {
    setisLoading(true);
    dispatch(CompanyStatus(companyId));
    setisLoading(false);
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  useEffect(() => {
    setisLoading(loader_status);
  }, [loader_status]);

  useEffect(() => {
    if (paymentintent) {
      setClientSecret(paymentintent?.client_secret);
      SetCusId(paymentintent?.customer);
    }
  }, [paymentintent]);

  useEffect(() => {
    const data = {
      amount: "10",
      currency: "usd",
    };
    dispatch(PaymentData(data));
  }, []);
  console.log("company_status", company_status);
  console.log("props", props);
  return (
    <>
      {isLaoding ? (
        <LoaderSpinner />
      ) : (
        <>
          {company_status ? (
            <div>
              {clientSecret ? (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    companyId={companyId}
                    cusData={cusId}
                    plan={props}
                  />
                </Elements>
              ) : (
                ""
              )}
            </div>
          ) : company_status === false ? (
            <BlockedPage />
          ) : null}
        </>
      )}
    </>
  );
}

export default StripeRegistration;
