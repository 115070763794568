import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import TourLayout from "layout/TourLayout/index";

import Loadable from "ui-component/Loadable";
import StripeRegistration from "views/pages/clients/StripeRegistration";
//Not Found
const PageNotfound = Loadable(
  lazy(() => import("views/utilities/PageNotFound"))
);

//  Company routing
const AddClients = Loadable(
  lazy(() => import("views/pages/Users/UserListing"))
);
const AddClientsSchedule = Loadable(
  lazy(() => import("views/pages/clients/AddClientsSchedule"))
);
const AppsEnvironment = Loadable(
  lazy(() => import("views/pages/clients/AppsEnvironment"))
);

const Downloads = Loadable(
  lazy(() => import("views/pages/DownloadsApps/index"))
);

// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
  path: "/",
  element: <TourLayout />,
  children: [
    {
      path: "/add-members",
      element: <AddClients />,
    },
    {
      path: "/add-schedule",
      element: <AddClientsSchedule />,
    },
    {
      path: "/apps-environment",
      element: <AppsEnvironment />,
    },
    {
      path: "/download-apps",
      element: <Downloads />,
    },
    {
      path: "*",
      element: <PageNotfound />,
    },
  ],
};

export default ClientRoutes;
