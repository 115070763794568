const config = {
  basename: "/",
  defaultPath: "/login",
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 12,
  Live_key:
    "pk_live_51P3PBUILNiCOm1tNnH4FG8wmCAXhR0AZD8HkGdR7xXa7qrY2YBfdS97tPX9r5Jvp0fwfmpFPh11ktlnUgGz7h24c00QhSP77zK",
  Public_key:
    "pk_test_51QlqgqGfLugCBMMlHSYgJeF9Vy6Ym2GEIznf68MHShzLRjQYYgmSOWxNLMUMQsN1vcgLDCmIFiggxsxU506rKzWF00Hg1ihFHK",
  paypal_client_id:
    "AVMk0t09KZmca49Tf2kYcbqT7ZStvlonYWueNlUf3nBnN05eIVSeCI7lGJcVkVl_tpOLw85a8kjrSkFb",
};

export default config;
