import React from "react";
import { Typography, Box } from "@mui/material";
import logo from "../../../assets/images/logo/logo.png";
function BlockedPage() {
  return (
    <>
      <Box className="flex flex-col justify-center items-center h-screen px-2 bg-[#e9b1b3]">
        <img src={logo} className="mb-10 logoanim" alt="FocusMonk" />
        <Typography
          variant="h3"
          gutterBottom
          className="heading-block text-center font-[400]"
        >
          Take a deep breath. You are Free.
        </Typography>
      </Box>
    </>
  );
}

export default BlockedPage;
