import {
  GET_ALL_USERS,
  GET_ALL_USERS_BY_COMPANY_ID,
  GET_ALL_USERS_BY_FILTER,
  GET_RANK_COINS,
  GET_USER_ACTIVITY,
  GET_USER_APP_ACTIVITY,
} from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";

export const GetAllUsers = () => (dispatch) => {
  dispatch(changeflag(true));

  Axios.get("/employees")
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const CheckCompanyUserEmail = (data, navigate) => (dispatch) => {
  return Axios.post("/adminuser/add_company_employee", data) // Return the Axios request
    .then((res) => {
      if (res.data.error == false) {
      } else {
        console.log("res", res);
        return res.data.message;
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
      throw error; // Ensure the error propagates
    });
};

export const CompanyUserAdd = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/adminuser/add_company_employee", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        if (data?.company_id) {
          let body = { company_id: data?.company_id };
          // dispatch(GetCoinAndRanks(body));
          // dispatch(GetAllUsersbyCompanyid(data?.company_id));
        }
        // navigate("/utils/users");
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const GetUserActivity = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/employees/listActivity", data)
    .then((res) => {
      dispatch({
        type: GET_USER_ACTIVITY,
        payload: res.data.trackingData,
      });
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const GetUserAppActivity = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/employees/listAppActivity", data)
    .then((res) => {
      dispatch({
        type: GET_USER_APP_ACTIVITY,
        payload: res.data.activityData,
      });
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const UserAdd = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/adminuser/add_employee", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        if (data?.company_id) {
          let body = { company_id: data?.company_id };
          dispatch(GetCoinAndRanks(body));
          dispatch(GetAllUsersbyCompanyid(data?.company_id));
        }
        navigate("/utils/users");
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const UpdateUser = (data, id, company_id, navigate) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.put("/employees/" + id, data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        if (company_id) {
          let body = { company_id: company_id };
          dispatch(GetCoinAndRanks(body));
          dispatch(GetAllUsersbyCompanyid(company_id));
        }
        if (navigate) {
          navigate("/utils/users");
        }
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const GetAllUsersbyCompanyid = (id) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.get("/employees/companyidget/" + id)
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS_BY_COMPANY_ID,
        payload: res.data.employees,
      });
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const getAllUsersbyFilter = (data) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.get("/employees/filteruser?" + data)
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS_BY_FILTER,
        payload: res.data.data,
      });
      if (!res.data.data || res.data.data?.length === 0) {
        toast.error("Record not Found");
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const DeleteUser = (id, company_id) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.delete("/employees/" + id)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        if (company_id) {
          let body = { company_id: company_id };
          dispatch(GetCoinAndRanks(body));
          dispatch(GetAllUsersbyCompanyid(company_id));
        }
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const DeleteImage = (data) => (dispatch) => {
  Axios.post("/upload/delete", data)
    .then((res) => {})
    .catch((error) => {});
};

export const UploadUserImage =
  (formData, data, navigate, status, id, company_id) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.post("/upload/addimage", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.error == false) {
          let path = res.data.path;
          data["image"] = path;

          if (status == "Add") {
            dispatch(UserAdd(data, navigate));
          }
          if (status == "Update") {
            dispatch(UpdateUser(data, id, company_id, navigate));
          }
        } else {
          toast.error(res.data.message);
        }
        dispatch(changeflag(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(changeflag(false));

        if (error.response) {
          toast.error(error?.response?.data?.message);
        } else if (error.request) {
          toast.error(error?.request);
        } else {
          toast.error(error?.message);
        }
      });
  };

export const UploadUsersCsv = (formData, company_id) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.post("/upload/excelup", formData)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        if (company_id) {
          // let body = { 'company_id': company_id }
          // dispatch(GetCoinAndRanks(body));
          dispatch(GetAllUsersbyCompanyid(company_id));
        } else {
          window.location.reload();
        }
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));

      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const GetCoinAndRanks = (id) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/coins/gettingranks", id)
    .then((res) => {
      dispatch({
        type: GET_RANK_COINS,
        payload: res.data.data,
      });
      dispatch(changeflag(false));
    })

    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        // toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};
