// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";

//Focus Monk

//Auth
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";
export const GET_FORGOT_DATA = "GET_FORGOT_DATA";
export const GET_REGISTER_MESSAGE = "GET_REGISTER_MESSAGE";
export const GET_TOKEN = "GET_TOKEN";
export const GET_STATUS = "GET_STATUS";

//Company Data
export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ALL_COMPANY_Filter = "GET_ALL_COMPANY_Filter";

//Users Data
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_BY_COMPANY_ID = "GET_ALL_USERS_BY_COMPANY_ID";
export const GET_RANK_COINS = "GET_RANK_COINS";
export const GET_ALL_USERS_BY_FILTER = "GET_ALL_USERS_BY_FILTER";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const GET_USER_APP_ACTIVITY = "GET_USER_APP_ACTIVITY";

//Schedule
export const GET_ALL_SCHEDULE = "GET_ALL_SCHEDULE";
export const GET_SCHEDULE_BY_ID = "GET_SCHEDULE_BY_ID";

//Apps Environment
export const GET_ALL_APPS_ENVIRONMENT = "GET_ALL_APPS_ENVIRONMENT";
export const GET_ALL_APPS_OF_COMPANY = "GET_ALL_APPS_OF_COMPANY";

//Loader Status
export const LOADER_STATUS = "LOADER_STATUS";
export const AUTHENTICATOR_STATUS = "AUTHENTICATOR_STATUS";

//Super Admin Dashboard
export const GET_SUPER_ADMIN_DASHBOARD = "GET_SUPER_ADMIN_DASHBOARD";

//Client Admin Dashboard
export const GET_CLIENT_ADMIN_DASHBOARD = "GET_CLIENT_ADMIN_DASHBOARD";

//Super Admin Notifications
export const GET_ADMIN_NOTIFICATION = "GET_ADMIN_NOTIFICATION";

//Client Admin Notifications
export const GET_CLIENT_NOTIFICATION = "GET_CLIENT_NOTIFICATION";

//Road Map
export const GET_ALL_ROADMAP = "GET_ALL_ROADMAP";

//Get Coins And Ranks
export const GET_COINS_RANKS = "GET_COINS_RANKS";
export const GET_PERFORMANCE_HISTORY = "GET_PERFORMANCE_HISTORY";

//SuperAdmin Apps
export const GET_ALL_OPEN_APPS = "GET_ALL_OPEN_APPS";

//Stripe Payment
export const GET_STRIPE_PAYMENTS = "GET_STRIPE_PAYMENTS";

//Payment Plan
export const GET_PAYMENT_PLAN = "GET_PAYMENT_PLAN";
export const PAYMENT_METHOD_SETTING = "PAYMENT_METHOD_SETTING";

// Subscriptions
export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";
export const GET_SUBSCRIPTION_ALL_HISTORY = "GET_SUBSCRIPTION_ALL_HISTORY";
export const GET_SUBSCRIPTION_HISTORY_BY_ID = "GET_SUBSCRIPTION_HISTORY_BY_ID";

//Language
export const GET_LANGUAGE = "GET_LANGUAGE";

//Reload type
export const GET_RELOAD = "GET_RELOAD";

//RankStatus
export const GET_RANK_STATUS = "GET_RANK_STATUS";
export const GET_RANKS = "GET_RANKS";

//MARKETPLACE
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";

//ORDERS
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
