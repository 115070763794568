import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Typography,
  Box,
  Button,
  Popover,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { BiEdit } from "react-icons/bi";
import LoaderSpinner from "../../../utils/Loader";
import moment from "moment";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllOrders,
  UpdateOrderStatus,
} from "../../../store/orders/order.action";
// project imports
import MainCard from "ui-component/cards/MainCard";
import ImgModal from "../../components/Modal/ImgModal";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { FaCoins } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// ==============================|| Clients ||============================== //

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      width: "12px",
      height: "12px",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      borderRadius: "10px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#555",
      },
    },
  },
});

function OrderListing() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [row, setRow] = useState([]);
  const { allOrders } = useSelector((state) => state?.order);
  const { loader_status } = useSelector((state) => state.loader);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [imgSrc, setImgSrc] = useState();
  const [memberhover, setMemberhover] = useState(null);
  const openmember = Boolean(memberhover);
  const [productStatus, setProductStatus] = useState("");
  const [productId, setProductId] = useState(null);

  useEffect(() => {
    setChecked(loader_status);
  }, [loader_status]);

  const imageClick = (e) => {
    setOpenImg(true);
    setImgSrc(e.target.src);
  };

  const columns = [
    {
      field: "id",
      hide: false,
      headerName: t("member_table_h_sr"),
      sortable: true,
      headerClassName: "super-app-theme--header",
      width: 50,
    },
    {
      field: "order_id",
      hide: false,
      headerName: t("order_id"),
      sortable: true,
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "customerName",
      headerName: t("customer_name"),
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "customerEmail",
      headerName: t("customer_email"),
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: t("total_price"),
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FaCoins color="#FFCC00" size={20} />
              <p>
                {param.row.price < 10
                  ? param.row.price + " " + t("coin")
                  : param.row.price + " " + t("coins")}
              </p>
            </Box>
          </>
        );
      },
    },
    {
      field: "total_items",
      headerName: t("total_items"),
      width: 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "shippingAddress",
      headerName: t("shipping_address"),
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "order_status",
      headerName: t("order_status"),
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (param) => {
        return (
          <Box>
            <p
              className={
                param.row.order_status === "Pending"
                  ? "text-[#FFCC00] font-[600]"
                  : param.row.order_status === "Approved"
                  ? "text-[green] font-[600]"
                  : "text-[red] font-[600]"
              }
            >
              {param.row.order_status}
            </p>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("member_table_h_cratedDate"),
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: t("member_table_h_action"),
      width: 300,
      headerClassName: "super-app-theme--header",
      selector: (row) => row.action,
      renderCell: (param) => {
        return (
          <div className="flex justify-center gap-4 items-center">
            <>
              <Button
                onClick={() => {
                  navigate("/order/details", {
                    state: { data: param.row.action },
                  });
                }}
                sx={{
                  background:
                    "linear-gradient(to bottom, #30496b, #30b8d2)!important",
                  padding: "1px 5px!important",
                  fontSize: "0.69rem!important",
                  borderRadius: "12px!important",
                }}
                className="flex items-center gap-1 leading-loose"
                size="small"
                variant="contained"
                type="button"
              >
                {t("action_view_details")}
                <FaEye className="text-base font-bold" />
              </Button>
              <Button
                onClick={() => handleOpenStatus(param.row.action)}
                sx={{
                  background: "#CCCCCC",
                  padding: "1px 8px!important",
                  fontSize: "0.8rem!important",
                  borderRadius: "15px!important",
                }}
                className="flex items-center gap-1 leading-loose text-black hover:bg-[#CCCCCC] shadow-lg bg-[#e9e6e6]"
                size="small"
                variant="contained"
                disabled={param.row.order_status === "Pending" ? false : true}
              >
                {t("action_edit")}
                <BiEdit className="text-base font-bold" />
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(GetAllOrders());
  }, [dispatch]);

  useEffect(() => {
    if (allOrders) {
      makeRow();
    }
  }, [allOrders]);

  const makeRow = () => {
    const products = allOrders?.orders;
    var data =
      Array.isArray(products) && products?.length > 0
        ? products.map((data, id) => ({
            id: id + 1,
            order_id: data.orderNumber == null ? "-" : data.orderNumber,
            customerName:
              data?.customer_name == null ? "-" : data.customer_name,
            customerEmail:
              data?.customer_email == null ? "-" : data.customer_email,
            productName:
              data?.product_id?.name == null ? "-" : data?.product_id?.name,
            productImage:
              data?.product_id?.image == null ? "-" : data?.product_id?.image,
            productDescription:
              data?.product_id?.description == null
                ? "-"
                : data?.product_id?.description,
            price: data?.totalprice == null ? "-" : data?.totalprice,
            order_status: data?.status == null ? "-" : data?.status,
            createdAt:
              moment(data?.createdAt).format("DD-MMM-YYYY") == null
                ? "-"
                : moment(data?.createdAt).format("DD-MMM-YYYY"),
            action: data,
            total_items: data?.totalItems == null ? "-" : data?.totalItems,
            shippingAddress:
              data?.shippingAddress == null ? "-" : data?.shippingAddress,
          }))
        : products;
    setRow(data);
  };

  const handleClose = () => setOpen(false);

  const handleOpenStatus = (data) => {
    if (data) {
      setProductStatus(data?.status);
      setProductId(data?._id);
      setOpen(true);
    }
  };

  const handleStatusChange = (e) => {
    setProductStatus(e.target.value);
  };

  const UpdateStatus = () => {
    if (productId) {
      const status = {
        status: productStatus,
      };
      if (status) {
        dispatch(UpdateOrderStatus(productId, status, handleClose, ""));
      }
    }
  };


  console.log(allOrders,'allOrders')

  return (
    <>
      {checked ? (
        <LoaderSpinner />
      ) : (
        <>
          <Box className="inline-block">
            <Typography
              aria-owns={open ? "MarketPlace" : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => {
                setMemberhover(e.currentTarget);
              }}
              onMouseLeave={() => {
                setMemberhover(null);
              }}
              variant="h3"
              className="text-[white] font-normal flex items-center mb-4 inline-block"
            >
              {t("orders")}
            </Typography>
            <Popover
              id="member"
              sx={{
                pointerEvents: "none",
              }}
              open={openmember}
              anchorEl={memberhover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={() => {
                setMemberhover(null);
              }}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>{t("order_hover")}</Typography>
            </Popover>
          </Box>

          <MainCard>
            <Box
              sx={{
                height: 600,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#E8E8E8",
                  color: "#307493",
                },
              }}
            >
              <DataGrid
                className={classes.root} // Apply the custom styles
                sx={{ border: "unset!important" }}
                rows={row}
                localeText={{
                  noRowsLabel: "No Data to show",
                }}
                columns={columns}
                disableSelectionOnClick
                pageSize={10}
                rowsPerPageOptions={[5]}
                components={{
                  Toolbar: GridToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </MainCard>
        </>
      )}

      <ImgModal open={openImg} close={setOpenImg} data={imgSrc} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px]">
          <Typography
            variant="h2"
            component="h2"
            className="font-[500] text-lg mb-6"
          >
            {t("change_order_status")}
          </Typography>
          <InputLabel id="demo-simple-select-label">
            {t("select_status")}
          </InputLabel>
          <Select
            className="w-full"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={productStatus}
            onChange={handleStatusChange}
          >
            <MenuItem value="Approved">{t("approved")}</MenuItem>
            <MenuItem value="Rejected">{t("rejected")}</MenuItem>
            <MenuItem value="Pending" hidden>
              {t("Pending")}
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={UpdateStatus}>{t("submit")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default OrderListing;
