import React from "react";
import { Box, Modal } from "@mui/material";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid transparent",
  borderRadius: "15px",
  boxShadow: 24,
  p: 2,
};
function ImgModal({ open, close, data }) {
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={() => close(!open)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className="xl:w-[550px] lg:w-[550px] md:w-[450px] sm:w-[370px] xsm:w-[350px] xxs:w-[320px] xxxs:w-[300px] xl:h-[550px] lg:h-[550px] md:h-[450px] sm:h-[450px] xsm:h-[450px] xxs:h-[420px] xxxs:h-[400px] grid place-content-center"
          >
            <InnerImageZoom src={data} zoomSrc={data} className="w-[100%]" />
            {/* <img src={data} alt="" className="w-[100%] object-cover" /> */}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default ImgModal;
