import { GET_LOGIN_DATA, GET_FORGOT_DATA, GET_STATUS } from "../actions";

const initialState = {
  login_Session: null,
  forgot_email: null,
  company_status: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FORGOT_DATA:
      return {
        ...state,
        forgot_email: action.payload,
      };
    case GET_LOGIN_DATA:
      return {
        ...state,
        login_Session: action.payload,
      };
    case GET_STATUS:
      return {
        ...state,
        company_status: action.payload.status,
      };
    default:
      return state;
  }
}
