// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
//redux
import { UpdateClientNotification } from "../../../../store/ClientNotification/ClientNotfication.action";
import {
  UpdateSuperAdminNotification,
} from "../../../../store/SuperAdminNotification/AdminNotfication.action";
// assets
import ReactTimeAgo from "react-time-ago";
import { useSelector, useDispatch } from "react-redux";
import {useState} from "react";
// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  // cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { login_Session } = useSelector((state) => state.auth);
  const { AdminNotification } = useSelector(
    (state) => state?.AdminNotification
  );
  const { ClientNotification } = useSelector(
    (state) => state?.ClientNotification
  );

  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: "5px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28,
  };

  const ReadHandler = (e, status, data) => {
    const value = {
      isread: status,
    };
    if (login_Session.isSuperadmin == true) {
      dispatch(UpdateSuperAdminNotification(value, data));
    } else {
      dispatch(UpdateClientNotification(value, data));
    }
  };
  return (
    <>
      {login_Session.isSuperadmin == true ? (
        <List
          sx={{
            width: "100%",
            maxWidth: 330,
            py: 0,
            borderRadius: "10px",
            [theme.breakpoints.down("md")]: {
              maxWidth: 300,
            },
            "& .MuiListItemSecondaryAction-root": {
              top: 22,
            },
            "& .MuiDivider-root": {
              my: 0,
            },
            "& .list-container": {
              pl: 7,
              cursor: 'auto !important',
            },
          }}
        >
          {AdminNotification?.data?.length > 0 ? (
            AdminNotification?.data?.slice(0, 6).map((data) => {
              return (
                <>
                  <ListItemWrapper>
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt="John Doe" src={data?.company_id?.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            {data?.company_id?.companyname}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                                timeStyle="twitter"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Grid
                      container
                      direction="column"
                      className="list-container"
                    >
                      <Grid item xs={12} sx={{ pb: 2 }}>
                        <Box className="flex items-center gap-6 justify-end">
                          {data?.isread == false ? (
                            <span className="dot"></span>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Typography variant="subtitle2" className="mb-2">
                          {data?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item>
                            <Button
                              onClick={(e) => {
                                ReadHandler(e, false, data);
                                setOpen(false);
                              }}
                              type="submit"
                              sx={{
                                padding: "0px !important",
                                cursor: 'pointer'
                              }}
                            >
                              <Chip label="Unread" sx={chipErrorSX} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={(e) => {
                                ReadHandler(e, true, data);
                                setOpen(false);
                              }}
                              type="submit"
                              sx={{ padding: "0px !important",     cursor: 'pointer' }}
                            >
                              <Chip label="Read" sx={chipWarningSX} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItemWrapper>
                  <Divider />
                </>
              );
            })
          ) : (
            <Box className="text-center font-normal text-base py-10">
              No Notifications Yet
            </Box>
          )}
        </List>
      ) : (
        <List
          sx={{
            width: "100%",
            maxWidth: 330,
            py: 0,
            borderRadius: "10px",
            [theme.breakpoints.down("md")]: {
              maxWidth: 300,
            },
            "& .MuiListItemSecondaryAction-root": {
              top: 22,
            },
            "& .MuiDivider-root": {
              my: 0,
            },
            "& .list-container": {
              pl: 7,
            },
          }}
        >
          {ClientNotification?.data?.length > 0 ? (
            ClientNotification?.data?.map((data) => {
              return (
                <>
                  <ListItemWrapper>
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt="John Doe" src={data?.employee_id?.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" className="truncate w-[70%]">
                            {data?.title}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                                timeStyle="twitter"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Grid
                      container
                      direction="column"
                      className="list-container"
                    >
                      <Grid item xs={12} sx={{ pb: 2 }}>
                        <Box className="flex justify-between items-center gap-6">
                          <Typography variant="subtitle2">
                            {data?.message ? data?.message : "No  Message"}
                          </Typography>
                          {data?.isread == false ? (
                            <span className="dot"></span>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item>
                            <Button
                              onClick={(e) => {
                                ReadHandler(e, false, data);
                                setOpen(false);
                              }}
                              type="submit"
                              sx={{ padding: "0px !important",
                                cursor: 'pointer' }}
                            >
                              <Chip label="Unread" sx={chipErrorSX} />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={(e) => {
                                ReadHandler(e, true, data);
                                setOpen(false);
                              }}
                              type="submit"
                              sx={{
                                padding: "0px !important",
                                cursor: 'pointer'
                            }}
                            >
                              <Chip label="Read" sx={chipWarningSX} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItemWrapper>
                  <Divider />
                </>
              );
            })
          ) : (
            <Box className="text-center font-normal text-base py-10">
              No Notifications Yet
            </Box>
          )}
        </List>
      )}
    </>
  );
};

export default NotificationList;
