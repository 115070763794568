import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import RoadMap from "views/utilities/RoadMap";
import AddRoadMap from "views/pages/RoadMap/AddRoadMap";
import OrderListing from "views/pages/Orders/OrderListing";
import Tracking from "views/pages/Tracking";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const DashboardClient = Loadable(
  lazy(() => import("views/clientDashboard/index"))
);

//Not Found
const PageNotfound = Loadable(
  lazy(() => import("views/utilities/PageNotFound"))
);

// utilities routing
const UtilsMetrics = Loadable(lazy(() => import("views/utilities/Metrics")));
const UtilsClients = Loadable(lazy(() => import("views/utilities/Clients")));
const UtilsClientsReport = Loadable(
  lazy(() => import("views/utilities/ClientsReport"))
);
const UtilsSchedule = Loadable(lazy(() => import("views/utilities/Schedule")));

//  User routing
const UserMetrics = Loadable(
  lazy(() => import("views/pages/Metrics/UserMetrics"))
);
const UserDetail = Loadable(
  lazy(() => import("views/pages/Metrics/UserDetail"))
);
const AllowedApps = Loadable(
  lazy(() => import("views/pages/Metrics/AllowedApps"))
);
const BlockedApps = Loadable(
  lazy(() => import("views/pages/Metrics/BlockedApps"))
);
const DistractedApps = Loadable(
  lazy(() => import("views/pages/Metrics/DistractedApps"))
);

//  Company routing
const AllCompanyDetails = Loadable(
  lazy(() => import("views/pages/Metrics/AllCompanyDetails"))
);
const CompanyDetails = Loadable(
  lazy(() => import("views/pages/Metrics/CompanyDetails"))
);
const CompanyAllowedApps = Loadable(
  lazy(() => import("views/pages/Metrics/CompanyAllowedApps"))
);
const CompanyBlockedApps = Loadable(
  lazy(() => import("views/pages/Metrics/CompanyBlockedApps"))
);
const CompanyDistractedApps = Loadable(
  lazy(() => import("views/pages/Metrics/CompanyDistractedApps"))
);

//  Company routing
const AddClients = Loadable(
  lazy(() => import("views/pages/clients/AddClients"))
);
const AddClientsSchedule = Loadable(
  lazy(() => import("views/pages/clients/AddClientsSchedule"))
);
const AppsEnvironment = Loadable(
  lazy(() => import("views/pages/clients/AppsEnvironment"))
);

// changePassword routing
const ChangePassword = Loadable(
  lazy(() => import("views/pages/changePassword/ChangePassword"))
);

//Client Report routes
const UsersReport = Loadable(
  lazy(() => import("views/pages/UserReport/UsersReport"))
);
const Invite = Loadable(lazy(() => import("views/pages/invite/Invite")));
const UserListing = Loadable(
  lazy(() => import("views/pages/Users/UserListing"))
);
const AddUsers = Loadable(lazy(() => import("views/pages/Users/AddUsers")));
const UserProfile = Loadable(
  lazy(() => import("views/pages/profile/UserProfile"))
);
const Pricing = Loadable(
  lazy(() => import("views/pages/Pricing/PricingTable"))
);
const Downloads = Loadable(
  lazy(() => import("views/pages/DownloadsApps/index"))
);
const RankStatus = Loadable(
  lazy(() => import("views/pages/RankStatus/RankStatus"))
);

// Stripe Page
const Stripe = Loadable(lazy(() => import("views/components/Stripe/Index")));
const Paypal = Loadable(lazy(() => import("views/components/PayPal/index")));

//View All Notifications
const AllNotification = Loadable(
  lazy(() =>
    import("layout/MainLayout/Header/NotificationSection/AllNotification")
  )
);

//Subscription History
const SubscriptionHistory = Loadable(
  lazy(() => import("views/pages/subscription_history/History"))
);

const Setting = Loadable(lazy(() => import("views/pages/settings/Setting")));
const MarketPlace = Loadable(
  lazy(() => import("views/pages/MarketPlace/ProductListing"))
);
const AddProduct = Loadable(
  lazy(() => import("views/pages/MarketPlace/AddProduct"))
);
const Orders = Loadable(lazy(() => import("views/pages/Orders/OrderListing")));
const OrderDetails = Loadable(
  lazy(() => import("views/pages/Orders/OrderDetail"))
);
// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardClient />,
    },
    {
      path: "",
      children: [
        {
          path: "client",
          element: <DashboardClient />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "users",
          element: <UserListing />,
        },
      ],
    },

    {
      path: "utils",
      children: [
        {
          path: "user-report",
          element: <UsersReport />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "rank-status",
          element: <RankStatus />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "market-place",
          element: <MarketPlace />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "orders",
          element: <Orders />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "schedule",
          element: <UtilsSchedule />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "road_map",
          element: <RoadMap />,
        },
      ],
    },
    {
      path: "road_map",
      children: [
        {
          path: "add",
          element: <AddRoadMap />,
        },
      ],
    },
    {
      path: "invite",
      children: [
        {
          path: "user",
          element: <Invite />,
        },
      ],
    },
    {
      path: "pricing",
      children: [
        {
          path: "",
          element: <Pricing />,
        },
      ],
    },
    {
      path: "download",
      children: [
        {
          path: "apps",
          element: <Downloads />,
        },
      ],
    },
    {
      path: "subscription",
      children: [
        {
          path: "history",
          element: <SubscriptionHistory />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "settings",
          element: <Setting />,
        },
      ],
    },
    {
      path: "/metrics/user-metrics",
      element: <UserMetrics />,
    },
    {
      path: "/metrics/user-activity",
      element: <Tracking />,
    },
    {
      path: "/metrics/user-detail",
      element: <UserDetail />,
    },
    {
      path: "/user-detail/allowed-apps",
      element: <AllowedApps />,
    },
    {
      path: "/user-detail/blocked-apps",
      element: <BlockedApps />,
    },
    {
      path: "/user-detail/distracted-apps",
      element: <DistractedApps />,
    },
    {
      path: "/metrics/company-metrics",
      element: <AllCompanyDetails />,
    },
    {
      path: "/metrics/company-details",
      element: <CompanyDetails />,
    },
    {
      path: "/company-details/company-allowed-apps",
      element: <CompanyAllowedApps />,
    },
    {
      path: "/company-details/company-blocked-apps",
      element: <CompanyBlockedApps />,
    },
    {
      path: "/company-details/company-distracted-apps",
      element: <CompanyDistractedApps />,
    },
    // {
    //     path: '/clients/add-clients',
    //     element: <AddClients />
    // },
    {
      path: "/add-clients/add-schedule",
      element: <AddClientsSchedule />,
    },
    {
      path: "/add-schedule/apps-environment",
      element: <AppsEnvironment />,
    },

    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/users/add-user",
      element: <AddUsers />,
    },
    {
      path: "/users/profile",
      element: <UserProfile />,
    },
    {
      path: "/view/notification",
      element: <AllNotification />,
    },
    {
      path: "/stripe/check-out",
      element: <Stripe />,
    },
    {
      path: "/paypal/check-out",
      element: <Paypal />,
    },
    {
      path: "/add/product",
      element: <AddProduct />,
    },
    {
      path: "/order/details",
      element: <OrderDetails />,
    },
    {
      path: "*",
      element: <PageNotfound />,
    },
  ],
};

export default ClientRoutes;
