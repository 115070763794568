import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  Divider,
  FormHelperText,
  InputAdornment,
  FormControl,
  useMediaQuery,
  InputLabel,
  IconButton,
  Autocomplete,
} from "@mui/material";
import toast from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
// third party
import * as Yup from "yup";
import { Formik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AnimateButton from "ui-component/extended/AnimateButton";
import { FaCamera } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  RoadMapAdd,
  UpdateRoadMap,
  UploadUserImage,
  DeleteImage,
} from "store/Road_Map/roadmap.action";
import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../../../utils/Loader";
import useScriptRef from "hooks/useScriptRef";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AddRoadMap({ ...others }) {
  const { t } = useTranslation();
  const scriptedRef = useScriptRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [file, setFile] = useState();
  const { login_Session } = useSelector((state) => state.auth);

  const [fileHover, setFileHover] = useState();
  const [ShowFile, setShowFile] = useState(false);
  const [ShowFileHover, setShowFileHover] = useState(false);

  const location = useLocation();
  const PropsData = useState(location.state);
  const props = PropsData[0]?.data;

  const { loader_status } = useSelector((state) => state.loader);
  const [isLaoding, setisLoading] = useState(false);

  useEffect(() => {
    setisLoading(loader_status);
  }, [loader_status]);

  function handleFileChange(e) {
    setFile({
      imagePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      imageAsFile: e.target.files[0],
    });
    setShowFile(true);
  }

  function handleFileChangeHover(e) {
    setFileHover({
      imagePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      imageAsFile: e.target.files[0],
    });
    setShowFileHover(true);
  }

  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: `#fff`,
      "& fieldset": {
        borderRadius: `15px`,
      },
      "& .MuiInputBase-input": {
        background: `#fff`,
      },
    },
  };

  return (
    <>
      {isLaoding ? (
        <LoaderSpinner />
      ) : (
        <>
          <Box className="flex justify-between mb-4">
            <Typography
              variant="h3"
              className="text-white font-normal flex items-center"
            >
              {t("add_road_map")}
            </Typography>
            <Button
              onClick={(e) => {
                window.history.back();
              }}
              type="submit"
              className="text-white text-[1.25rem] font-normal"
            >
              <ArrowBackIcon /> {t("back_btn")}
            </Button>
          </Box>
          <MainCard>
            <Typography
              variant="h4"
              className="text-primary-text font-normal flex items-center mb-2"
            >
              {t("add_road_map_desc")}
            </Typography>
            <Divider
              className="text-gray mb-8"
              sx={{ borderBottomWidth: "2px" }}
            />

            <Formik
              initialValues={{
                title: props ? props.title : "",
                coins: props ? props.coins : "",
                ordernumber: props ? props.ordernumber : "",
                file: props ? props.image : null,
                hover_image: props ? props.hover_image : null,
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().max(255).required("Title is required"),
                coins: Yup.string().max(255).required("Coins is required"),
                ordernumber: Yup.string()
                  .max(255)
                  .required("Order Number is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  if (scriptedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);

                    if (props) {
                      if (file?.imageAsFile || fileHover?.imageAsFile) {
                        if (file?.imageAsFile && values.file) {
                          dispatch(DeleteImage({ image: values.file }));
                        }
                        if (fileHover?.imageAsFile && values.hover_image) {
                          dispatch(DeleteImage({ image: values.hover_image }));
                        }

                        const data = {
                          title: values.title,
                          coins: values.coins,
                          ordernumber: values.ordernumber,
                        };

                        const formData = new FormData();

                        if (file?.imageAsFile) {
                          formData.append("image", file.imageAsFile);
                        }
                        if (fileHover?.imageAsFile) {
                          formData.append("file_hover", fileHover.imageAsFile);
                        }

                        dispatch(
                          UploadUserImage(
                            formData,
                            data,
                            navigate,
                            "Update",
                            props._id
                          )
                        );
                      } else {
                        const data = {
                          title: values.title,
                          coins: values.coins,
                          ordernumber: values.ordernumber,
                        };
                        dispatch(UpdateRoadMap(data, props._id, navigate));
                      }
                    } else {
                      if (file?.imageAsFile || fileHover?.imageAsFile) {
                        const data = {
                          title: values.title,
                          coins: values.coins,
                          ordernumber: values.ordernumber,
                        };

                        const formData = new FormData();

                        if (file?.imageAsFile) {
                          formData.append("image", file.imageAsFile);
                        }
                        if (fileHover?.imageAsFile) {
                          formData.append("file_hover", fileHover.imageAsFile);
                        }

                        dispatch(
                          UploadUserImage(formData, data, navigate, "Add")
                        );
                      } else {
                        const data = {
                          title: values.title,
                          coins: values.coins,
                          ordernumber: values.ordernumber,
                        };
                        dispatch(RoadMapAdd(data, navigate));
                      }
                    }
                  }
                } catch (err) {
                  console.log(err);
                  if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                  <Grid
                    container
                    spacing={matchDownSM ? 0 : 2}
                    className="gap-y-4"
                  >
                    {login_Session?.isSuperadmin && (
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <div className="form-control flex gap-2 justify-center">
                            {ShowFile === true ? (
                              <img
                                src={
                                  file.imagePreview
                                    ? file.imagePreview
                                    : values.file
                                    ? values.file
                                    : ""
                                }
                                alt="logo"
                                className="w-[80px] h-[80px]"
                              />
                            ) : (
                              <label
                                htmlFor="companyLogo"
                                className="flex items-center justify-center w-[70px] h-[70px] border-dashed border-2 border-[#707070] bg-[#F5F5F5] p-4"
                              >
                                <FaCamera className="text-4xl text-[#ABABAB]" />
                              </label>
                            )}
                            <div className="grid place-content-end gap-y-2">
                              <label htmlFor="ba">{t("add_icon")}</label>
                              <input
                                type="file"
                                className="custom-file-input"
                                id="file"
                                name="file"
                                accept="image/*"
                                multiple
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <div className="form-control flex gap-2 justify-center">
                            {ShowFileHover === true ? (
                              <img
                                src={
                                  fileHover.imagePreview
                                    ? fileHover.imagePreview
                                    : values.hover_image
                                    ? values.hover_image
                                    : ""
                                }
                                alt="logo"
                                className="w-[80px] h-[80px]"
                              />
                            ) : (
                              <label
                                htmlFor="companyLogo"
                                className="flex items-center justify-center w-[70px] h-[70px] border-dashed border-2 border-[#707070] bg-[#F5F5F5] p-4"
                              >
                                <FaCamera className="text-4xl text-[#ABABAB]" />
                              </label>
                            )}
                            <div className="grid place-content-end gap-y-2">
                              <label htmlFor="ba">{t("add_hover_icon")}</label>
                              <input
                                type="file"
                                className="custom-file-input"
                                id="file"
                                name="file_hover"
                                accept="image/*"
                                multiple
                                onChange={handleFileChangeHover}
                              />
                            </div>
                          </div>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <InputLabel htmlFor="title" className="text-black">
                        {t("enter_title_name")} :
                      </InputLabel>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.title && errors.title)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <TextField
                          fullWidth
                          placeholder="Basic"
                          margin="normal"
                          name="title"
                          type="text"
                          value={values.title}
                          sx={{
                            marginTop: "0px!important",
                            marginBottom: "0px!important",
                            ...inputStyle,
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.title && errors.title && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text--register"
                          >
                            {errors.title}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {login_Session?.isSuperadmin && (
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel htmlFor="coins" className="text-black">
                            {t("enter_coins")} :
                          </InputLabel>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.coins && errors.coins)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <TextField
                              fullWidth
                              placeholder="5000"
                              margin="normal"
                              name="coins"
                              type="number"
                              value={values.coins}
                              sx={{
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                                ...inputStyle,
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            {touched.coins && errors.coins && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text--register"
                              >
                                {errors.coins}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel
                            htmlFor="ordernumber"
                            className="text-black"
                          >
                            {t("enter_order_number")} :
                          </InputLabel>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.ordernumber && errors.ordernumber
                            )}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <TextField
                              fullWidth
                              placeholder="1"
                              margin="normal"
                              name="ordernumber"
                              type="number"
                              value={values.ordernumber}
                              sx={{
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                                ...inputStyle,
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            {touched.ordernumber && errors.ordernumber && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text--register"
                              >
                                {errors.ordernumber}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container justifyContent="end">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={{ mt: 6, textAlign: "end" }}>
                        <AnimateButton>
                          <Button
                            disableElevation
                            size="large"
                            type="submit"
                            variant="contained"
                            className="rounded-[13px] max-w-[80%] text-lg bg-custom border-2 border-primary-text border-solid leading-6"
                          >
                            {props ? t("update") : t("submit_btn")}
                          </Button>
                        </AnimateButton>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
}

export default AddRoadMap;
