import { GET_ALL_ORDERS } from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";

export const GetAllOrders = () => (dispatch) => {
  dispatch(changeflag(true));
  Axios.get("/order")
    .then((res) => {
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res.data,
      });
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.message);
        dispatch({
          type: GET_ALL_ORDERS,
          payload: null,
        });
      }
      else if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const UpdateOrderStatus =
  (id, data, handleClose, navigate) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.put("/order/update/" + id, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.error == false) {
          toast.success(res.data.message);
          dispatch(GetAllOrders());
          if (handleClose) {
            handleClose();
          } else {
            navigate("/utils/orders");
          }
        } else {
          toast.error(res.data.message);
        }
        dispatch(changeflag(false));
      })
      .catch((error) => {
        dispatch(changeflag(false));
        if (error.response) {
          toast.error(error?.response?.data?.message);
        } else if (error.request) {
          toast.error(error?.request);
        } else {
          toast.error(error?.message);
        }
      });
  };
