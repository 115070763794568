import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import authReducer from "./auth/auth.reducer";
import loaderReducer from "./loader/loader.reducer";
import CompanyReducer from "./company/company.reducer";
import SchdeuleReducer from "./schedule/schedule.reducer";
import AppsEnvironmentReducer from "./appsenvironment/apps.reducer";
import UsersReducer from "./user/user.reducer";
import SuperAdminReducer from "./superAdmin/dashboard.reducer";
import ClientAdminReducer from "./clientAdmin/Admin.reducer";
import AdminNotificationReducer from "./SuperAdminNotification/AdminNotfication.reducer";
import ClientNotificationReducer from "./ClientNotification/ClientNotfication.reducer";
import RoadMapReducer from "./Road_Map/roadmap.reducer";
import OpenAppsReducer from "./superApps/superapps.reducer";
import CoinRankReducer from "./coins/coins.reducer";
import PaymentReducer from "./stripePayment/payment.reducer";
import paymentplanReducer from "./paymentPlan/paymentplan.reducer";
import freetrialReducer from "./paymentPlan/paymentplan.reducer";
import ReloadReducer from "./reload/reload.reducer";
import GetRankStatus from "./RankStatus/RankStatus.reducer";
import subscriptionReducer from "./subscription/subscription.reducer";
import languageReducer from "./language/language.reducer";
import marketPlaceReducer from "./marketPlace/marketPlace.reducer";
import orderReducer from "./orders/order.reducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  AllLanguage: languageReducer,
  customization: customizationReducer,
  auth: authReducer,
  loader: loaderReducer,
  Company: CompanyReducer,
  Users: UsersReducer,
  Schedule: SchdeuleReducer,
  AppsEnvironment: AppsEnvironmentReducer,
  SuperAdmin: SuperAdminReducer,
  ClientAdmin: ClientAdminReducer,
  AdminNotification: AdminNotificationReducer,
  ClientNotification: ClientNotificationReducer,
  RoadMap: RoadMapReducer,
  OpenApps: OpenAppsReducer,
  CoinRank: CoinRankReducer,
  paymentintent: PaymentReducer,
  Plans: paymentplanReducer,
  Reload: ReloadReducer,
  AllRankStatus: GetRankStatus,
  SubscriptionPlan: subscriptionReducer,
  marketPlace: marketPlaceReducer,
  order: orderReducer,
});

export default reducer;
