import { useRoutes } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// routes
import MainRoutes from './MainRoutes';
import ClientRoutes from './ClientRoutes';
import TourRoutes from './TourRoutes';

import AuthenticationRoutes from './AuthenticationRoutes';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { login_Session } = useSelector((state) => state.auth);

    let cto_route;
    if (login_Session?.isSuperadmin === true) {
        cto_route = [
            MainRoutes,
            AuthenticationRoutes
        ]
    } else {
        cto_route = [
            ClientRoutes,
            AuthenticationRoutes,
            TourRoutes
        ]
    }


    return useRoutes(
        cto_route
    );
}
