import React, { useState, useCallback, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import MainCard from "ui-component/cards/MainCard";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router";
import { CheckCompanyUserEmail, CompanyUserAdd } from "store/user/user.action";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function CheckoutForm({ companyId }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [touched, setTouched] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [errmsg, setErrmsg] = useState(null);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const baseUrl = new URL(window.location.href).origin;
  console.log("baseUrl", baseUrl);
  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: `#fff`,
      "& fieldset": {
        borderRadius: `6px`,
      },
      "& .MuiInputBase-input": {
        background: `#fff`,
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setBtnLoading(false);
      return;
    }

    setBtnLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      billingDetails: {
        email,
      },
      confirmParams: {
        return_url: `${baseUrl}/payment-successful`, // Not needed for handling manually
      },
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message);
    } else if (paymentIntent?.status === "succeeded") {
      console.log("Payment successful:", paymentIntent);
      await registerEmployee(false);
      window.location.href = `${baseUrl}/payment-successful`;
    } else {
      console.log("Payment processing:", paymentIntent?.status);
    }
    setBtnLoading(false);
  };

  const debouncedRegisterEmployee = useCallback(
    debounce(() => registerEmployee(true), 800), // Adjust delay as needed
    [email]
  );

  // Trigger debounced function on email change
  useEffect(() => {
    if (email) {
      debouncedRegisterEmployee();
    }
    return () => {
      debouncedRegisterEmployee.cancel();
    };
  }, [email, debouncedRegisterEmployee]);

  async function registerEmployee(typeCheck) {
    setEmailLoading(true);
    const data = {
      company_id: companyId,
      email: email,
      typeCheck,
      first_name: email.split("@")[0],
      last_name: "",
      password: "asdf1234",
    };

    try {
      const emailStatus = await dispatch(CheckCompanyUserEmail(data, navigate)); // Await the function call
      console.log("emailStatus", emailStatus);
      setErrmsg(emailStatus);
    } catch (error) {
      console.error("Error registering employee:", error);
    }
    setEmailLoading(false);
  }

  const customStyle = {
    width: "100%",
    "&.MuiPaper-root": {
      "& .MuiCardContent-root": {
        width: "100%",
      },
    },
  };

  return (
    <>
      <MainCard
        sx={customStyle}
        className="h-screen border-0 flex justify-center items-center overflow-y-auto"
      >
        <Grid container spacing={2} className="h-screen ovewflow-y-auto">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="bg-card !bg-center"
          ></Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="flex justify-between mb-6 bg-white">
              <Typography gutterBottom variant="h3" className="font-normal">
                Payment details
              </Typography>
            </Box>
            <InputLabel htmlFor="email" className="text-black">
              {t("enter_your_email")}
            </InputLabel>
            <FormControl
              fullWidth
              error={Boolean(!email)}
              sx={{ ...theme.typography.customInput }}
            >
              <Box display={"flex"}>
                {" "}
                <TextField
                  sx={inputStyle}
                  id="outlined-adornment-email-register"
                  type="email"
                  value={email}
                  fullWidth
                  name="email"
                  onBlur={() => setTouched(true)} // Set touched to true on blur
                  placeholder={t("enter_your_email")}
                  onChange={(e) => {
                    setErrmsg("");
                    setemail(e.target.value);
                  }}
                />
                {emailLoading && <CircularProgress className="ms-3" />}
              </Box>
              {errmsg ? (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errmsg}
                </FormHelperText>
              ) : (
                touched &&
                !email && ( // Update condition to show error message
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errmsg ? errmsg : "Email is required"}
                  </FormHelperText>
                )
              )}
            </FormControl>

            <form className="mt-2" onSubmit={handleSubmit}>
              <PaymentElement />
              <Box className="mt-8 mb-4 flex justify-center">
                <Button
                  type="submit"
                  className="text-white rounded-xl w-[50%] text-[1.2rem]"
                  disabled={!stripe || !elements || btnLoading}
                  sx={{
                    background:
                      "linear-gradient(to bottom, #30496b, #30b8d2)!important",
                  }}
                  size="large"
                  variant="contained"
                >
                  {btnLoading ? "Processing..." : "Pay"}
                </Button>
              </Box>
              {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}
              {message && <div style={{ color: "green" }}>{message}</div>}
            </form>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

export default CheckoutForm;
